import { ReportHandler } from "web-vitals";
import { initializeFaro, ReactIntegration, getWebInstrumentations} from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { LogLevel } from '@grafana/faro-core';


export const reportWebVitals = (onPerfEntry?: ReportHandler) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import("web-vitals").then(
            ({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
                getCLS(onPerfEntry);
                getFID(onPerfEntry);
                getFCP(onPerfEntry);
                getLCP(onPerfEntry);
                getTTFB(onPerfEntry);
            }
        );
    }
};

export const reportWebVitalsToGrafanaFaro = () => {

  
  initializeFaro({
    url: process.env.REACT_APP_GRAFANA_FARO_URL,
    app: {
      name: 'kenko-app',
      version: '1.0.0',
      environment: process.env.REACT_APP_STAGE,
      namespace: 'k3o.app'
    },
    
    instrumentations: [
      // Mandatory, omits default instrumentations otherwise.
      ...getWebInstrumentations({
        captureConsole: true,
        
        captureConsoleDisabledLevels: [LogLevel.DEBUG, LogLevel.TRACE],
      }),
  
      // Tracing package to get end-to-end visibility for HTTP requests.
      new TracingInstrumentation(),
  
      // React integration for React applications.
      new ReactIntegration(),
    ],
  });
};